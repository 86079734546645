@import url("https://fonts.googleapis.com/css?family=Roboto Slab|Playfair Display|Work Sans");

.App-header {
  border-width: 0 0 1px 0;
  border-color: #606C38;
  background-color: #FFFFFF;
  opacity: 0.95;
}

.App-footer {
  background-color: #283618;
  color: #FEFAE0;
}

.picture-box {
  border-radius: 3%;
  border-width: 7px;
  border-color: #F5CAC3;
}